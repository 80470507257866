import React from "react"
import BlogPost from "../components/BlogPost";
import MetaHead from "../components/MetaHead";
import Layout from "../components/Layout";
import {graphql} from "gatsby"

const Blog = ({data, pageContext, path}) => {
	const articles = data.allContentfulBlogPost
	const essentialData = data.contentfulEssentialData
	return (
		<>
		<MetaHead meta={data.contentfulMetaFields} lang={pageContext.langKey} path={path} />
		<Layout essentialData={{...essentialData, ...pageContext, path}}>
			<h1>{essentialData.blogTitle}</h1>
			{
				articles.nodes.map((blog_post) => {
					return (
						<BlogPost 
						key={blog_post.slug}
						uid={blog_post.slug}
						title={blog_post.title}
						date={blog_post.date}
						subtitle={blog_post.subtitle.subtitle}
						excerpt={blog_post.excerpt.excerpt}
						langKey={pageContext.langKey}
						/>
					)
				})
			}
		</Layout>
		</>
	);
}
 
export const query = graphql`
query($langKey: String!){
	allContentfulBlogPost(filter: {node_locale: {eq: $langKey}}) {
		nodes {
			title
			date(formatString: "DD.MM.YYYY")
			slug
			subtitle {
				subtitle
			}
			excerpt{
				excerpt
			}
		}
	}
	contentfulEssentialData(node_locale: {eq: $langKey}) {
		workTitle
		resourcesTitle
		footerCopyright
		footerContact
		blogTitle
		aboutTitle
		homeTitle
		imprintTitle
	}
	contentfulMetaFields(identifier: {eq: "blog"}, node_locale: {eq: $langKey}) {
		title
		ogType
		description {
			description
		}
	}
}`

export default Blog;

